/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Avatar
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-avatar {
  --background: var(--color-primary-300);
  --color: var(--color-foreground-dark);

  display: inline-block;
  padding: 0.3em;
  overflow: hidden;
  font-size: 1em;
  line-height: 0;
  color: var(--color);
  background-color: var(--background);
  border-radius: 1em;
}

  .c-avatar__figure {}

  .c-avatar__icon {
    font-size: 0.4em;
    vertical-align: top;
  }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-avatar--figure {
  padding: 0;
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-avatar {
    break-inside: avoid;
    border: rem(1px) solid color("black");
  }
}
