/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Gate
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-gate {
  --color: var(--color-foreground);
  --background: var(--color-background);

  position: relative;
  overflow: hidden;
  border-radius: rem(10px);
}

  .c-gate__head,
  .c-gate__body,
  .c-gate__foot {
    padding: rem(20px 24px);
    color: var(--color);
    background-color: var(--background);

    &:first-child {
      padding-top: rem(30px);
    }

    &:last-child {
      padding-bottom: rem(30px);
    }
  }

  .c-gate__head,
  .c-gate__foot {
    text-align: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(8px);
    }
  }

  .c-gate__head {
    --color: var(--color-secondary-900);
  }

    .c-gate__title {
      @include h6;
      font-family: var(--font-brand);
      font-weight: $font-bold;

      .c-gate__head & {
        @include h4;

        color: var(--color-secondary-800);
      }
    }

    .c-gate__subtitle {
      @include text-small;
    }

  .c-gate__body {
    .c-gate__head + & {
      padding-top: 0;
    }
  }

  .c-gate__foot {
    padding-bottom: rem(30px);
    text-align: center;
    background-color: var(--color-warning-50);
  }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-gate {
    break-inside: avoid;
  }
}
