/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Brick
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$brick-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-brick {
  --background: transparent;
  --color: inherit;
  --border: currentcolor;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: rem(200px);
  padding: rem(4px);
  font-size: rem($brick-font-size);
  color: var(--color);
  background: var(--background);
  border: rem(1px) solid var(--border);
  border-radius: rem(10px);
}

  .c-brick__head,
  .c-brick__body,
  .c-brick__foot {
    padding: rem(15px);
  }

  .c-brick__head {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

    .c-brick__mainlink {
      position: static;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  .c-brick__body {
    flex-grow: 1;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(10px);
    }
  }

    .c-brick__badge {}

    .c-brick__richtext {
      --vertical-space: #{ rem(10px) };
    }

  .c-brick__foot {
    flex: 0 0 auto;
  }

    .c-brick__actions {
      display: flex;
      justify-content: space-between;
      margin: rem(-5px);
    }

      .c-brick__action {
        margin: rem(5px);
      }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-brick--error {
  --background: var(--color-error-50);
  --border: var(--color-error-500);

  padding: rem(2px);
  border-width: rem(3px);
}

.c-brick--flat {
  --background: var(--color-background);
  --border: transparent;

  padding: rem(18px);
  border-width: rem(3px);

  .c-brick__head,
  .c-brick__body,
  .c-brick__foot {
    padding: rem(10px);
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-brick {
    break-inside: avoid;
  }
}
