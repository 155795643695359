/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Profile
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-profile {
  position: relative;
  display: inline-flex;
  align-items: center;
}

    .c-profile__avatar {
      @include fluid-size(72px, 80px);
    }

    .c-profile__title {
      @include title($root: "profile");
      @include fluid-size(16px, 18px);

      font-weight: $font-bold;

      .c-profile__avatar + & {
        margin-left: rem($spacing-small);
      }
    }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-profile {
    break-inside: avoid;
  }
}
