/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Menu
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$menu-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-menu {
  font-size: $menu-font-size;
}

  .c-menu__head,
  .c-menu__body,
  .c-menu__foot {
    @include fluid-size($spacing-regular, $spacing-large, padding-top);
    @include fluid-size($spacing-regular, $spacing-large, padding-bottom);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:first-child) {
      border-top: rem(1px) solid var(--color-primary-300);
    }
  }

  .c-menu__head {}

    .c-menu__avatar {}

  .c-menu__body {}

    .c-menu__items {
      padding: 0;
      margin: 0;
      list-style: none;
    }

      .c-menu__item {
        + .c-menu__item {
          margin-top: rem($spacing-regular);
        }
      }

        .c-menu__control {
          text-decoration: none;

          &[aria-current] {
            font-weight: $font-bold;
          }

          &:hover {
            text-decoration: underline;
          }
        }

  .c-menu__foot {}

    .c-menu__link {}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-menu {
    break-inside: avoid;
  }
}
