/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Dashboard
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$dashboard-min-width: 320px !default;
$dashboard-min-ratio: 50% !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-dashboard {
  --min-width: #{ rem($dashboard-min-width) };
  --min-ratio: #{ $dashboard-min-ratio };
  --gap: #{ rem($spacing-large) };

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);
}

  .o-dashboard__sidebar {
    flex-basis: var(--min-width);
    flex-grow: 1;
    width: var(--min-width);
    max-width: 100%;
  }

  .o-dashboard__board {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--min-ratio);
  }

    .o-dashboard__wrapper {}

    .o-dashboard__foot {
      padding-top: rem($spacing-regular);
      border-top: rem(1px) solid var(--color-primary-300);

      &:not(:first-child) {
        @include fluid-size(30px, 50px, margin-top);
      }
    }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.o-dashboard--togglable {
  --gap: 0;

  @include fluid-size(20px, 60px, padding-top);
  @include fluid-size(40px, 60px, padding-bottom);

  .o-dashboard__sidebar {
    display: none;
    padding: rem($spacing-regular $wrapper-gutter);

    .no-js &,
    .header-mounted &:not([aria-hidden]) {
      display: block;
    }

    &[aria-hidden] {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: $global-header-zindex - 1;
      height: 100vh;
      height: calc(100vh);
      padding-top: var(--header-height);
      overflow-x: hidden;
      overflow-y: auto;
      overscroll-behavior: contain;
      color: var(--color-foreground);
      background-color: var(--color-background);
      animation: dashboard-sidebar-show $animations-transition-duration $animations-timing-function;
      @include transition(opacity transform);

      &.is-hiding {
        opacity: 0;
        transform: translate3d(#{ rem(-1 * $spacing-regular) }, 0, 0);
      }
    }

    &[aria-hidden="false"] { display: block; }
    &[aria-hidden="true"] { display: none; }
  }

  .o-dashboard__board {
    padding-top: rem($spacing-regular);
  }

  @include mq($until: large) {
    --min-width: 100%;
    --min-ratio: 100%;
  }

  @include mq($from: large) {
    .o-dashboard__wrapper {
      margin-right: 0;
    }
  }
}

.o-dashboard--sticky {
  .o-dashboard__sidebar {
    > * {
      position: sticky;
      top: rem($spacing-regular);
    }
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes dashboard-sidebar-show {
  from {
    opacity: 0;
    transform: translate3d(#{ rem(-1 * $spacing-regular) }, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
